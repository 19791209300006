
import { NavLink } from "react-router-dom";

interface NavbarProps {
  switchPage: (page: string) => void;
  activePage: string;
};

const Navbar: React.FC<NavbarProps> = ({ switchPage, activePage }) => {
  return (
    <div className="flex flex-row justify-center">
      <div className="" onMouseEnter={() => switchPage('home')}>
        <MenuButton label="Home"    to="home" isPageActive={activePage==='home' || activePage==='' } onClick={() => switchPage('home')} />
      </div>
      <div onMouseEnter={() => switchPage('presentation')}>
        <MenuButton label="Presentation"   to="presentation" isPageActive={activePage==='presentation'} onClick={() => switchPage('presentation')} />
      </div>
      {/* <div onMouseEnter={() => switchPage('blog')}>
        <MenuButton label="Blog"   to="blog" isPageActive={activePage==='blog'} onClick={() => switchPage('blog')} />
      </div> */}
      <div onMouseEnter={() => switchPage('projects')}>
        <MenuButton label="Projects"   to="projects" isPageActive={activePage==='projects'} onClick={() => switchPage('projects')} />
      </div>
    </div>
  );
};

interface MenuButtonProps {
  label: string;
  to: string;
  isPageActive: boolean;
  onClick: () => void;
};

const MenuButton: React.FC<MenuButtonProps> = ({ label, to, isPageActive, onClick }) => {
  return (
    <NavLink
      to={to}
      onClick={onClick}
      className={() => {
        const currentMenu = "transition duration-500 bg-neutral-300 hover:bg-neutral-300 dark:bg-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-600 dark:hover:text-neutral-50";
        const notCurrentMenu = "transition duration-500 text-neutral-500 hover:text-neutral-900 dark:text-neutral-300 dark:hover:text-neutral-50 ";
        return "mx-1 py-2 px-4 rounded-lg hover:cursor-pointer hover:scale-105 " + (isPageActive ? currentMenu : notCurrentMenu);
      }}>
      {label}
    </NavLink>
  )
};

export default Navbar;
