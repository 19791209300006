
const OperatorButton: React.FC<{
  sign: string;
  onClick: () => void;
}> = ({ sign, onClick }) => (
  <div className="relative w-10 h-14">
    <button
      className={
        'w-full h-full rounded-lg shadow-lg flex items-center justify-center text-xl font-semibold transform transition duration-200 bg-gradient-to-r from-white to-gray-50 border border-gray-200 hover:scale-105 hover:shadow-xl hover:border-blue-400 active:scale-95 active:bg-blue-50'
      }
      onClick={onClick}
    >
      {sign}
    </button>
  </div>
);


export default OperatorButton;