import React, { useEffect } from 'react';
import './App.css';
import "@fontsource/ibm-plex-sans";

import { BrowserRouter as Router, Route, Routes, RouterProvider, createBrowserRouter, useLocation } from 'react-router-dom';

import Home from './components/home/Home';
import Mondrian from './components/mondrian/Mondrian';
import HomeV2 from './components/v2/Home';
import Fermat from './components/fermat/fermat';
import loadClickyScript from './tools/clicky/useClicky';

const router = createBrowserRouter([
  {
    path: "*",
    Component: Root,
  }
]);

function Root() {
  return (
    <>
      <RouteChangeTracker />
      <Routes>
        {/* <Route path="/v1" Component={Home} /> */}
        <Route path="/mondrian" Component={Mondrian} />
        <Route path="/fermat" Component={Fermat} />
        <Route path="/*" Component={HomeV2} />
      </Routes>
    </>
  )
};

const App: React.FC = () => {
  useEffect(() => {
    loadClickyScript('101436159')
  });

  return (
    <RouterProvider router={router}></RouterProvider>
  );
};

const RouteChangeTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.clicky) {
      window.clicky.log(location.pathname, document.title);
    }
  }, [location]);

  return null;
}; 

export default App;
