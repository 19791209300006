import React from 'react';
import { useTranslation } from 'react-i18next';

const Projects: React.FC = () => {
  const { t } = useTranslation();

  const projects = [
    {
      title: "Personal Website",
      description: "This website, built with React, TypeScript, and Tailwind CSS. Features dark mode, internationalization, and responsive design.",
      technologies: ["React", "TypeScript", "Tailwind CSS", "i18next"],
      link: "/"
    },
    {
      title: "Fermat",
      description: "An interactive game to find a number, using basic arithmetic operations.",
      technologies: ["React", "TypeScript", "Tailwind CSS"],
      link: "/fermat"
    }
  ];

  return (
    <div className="w-full flex flex-col gap-4 py-6">
      {/* PROJECTS TITLE */}
      <div
        className="animate-in flex flex-col mt-4"
        style={{ "--index": 1 } as React.CSSProperties}
      >
        <div className="text-md sm:text-lg font-bold">Projects</div>
        <div className="text-sm sm:text-base text-neutral-500 dark:text-neutral-300 text-justify">
          A showcase of my personal projects and contributions to open source software.
        </div>
      </div>

      {/* PROJECTS LIST */}
      <div
        className="animate-in flex flex-col gap-4"
        style={{ "--index": 2 } as React.CSSProperties}
      >
        {projects.map((project, index) => (
          <div
            key={index}
            className="p-4 rounded-lg border border-neutral-200 dark:border-neutral-700 hover:border-blue-400 dark:hover:border-neutral-500 transition duration-200"
          >
            <a
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
            >



              <h3 className="text-base sm:text-lg font-semibold mb-2">{project.title}</h3>
              <p className="text-sm sm:text-base text-neutral-600 dark:text-neutral-300 mb-3">
                {project.description}
              </p>
              <div className="flex flex-wrap gap-2">
                {project.technologies.map((tech, techIndex) => (
                  <span
                    key={techIndex}
                    className="px-2 py-1 text-xs rounded-full bg-neutral-200 dark:bg-neutral-700"
                  >
                    {tech}
                  </span>
                ))}
              </div>

            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
