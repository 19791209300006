import React from 'react';

// type ArithmeticOperator = '+' | '-' | 'x' | '/';

type NumberCardProps = {
  n: number | string;
  used: boolean;
  onNumberClick: () => void;
};

const NumberCard: React.FC<NumberCardProps> = ({n, used, onNumberClick}) => {
  
  return (
    <div className="relative w-12 h-16">
      <button 
        className={
          'w-full h-full rounded-lg shadow-lg flex items-center justify-center text-xl font-semibold transform transition duration-200 ' +
          (!used ? 'bg-gradient-to-r from-white to-gray-50 border border-gray-200 hover:scale-105 hover:shadow-xl hover:border-blue-400 active:scale-95 active:bg-blue-50' : 'bg-gray-100 border border-gray-200 opacity-50 cursor-not-allowed')
        }
        onClick={() => typeof n === 'number' ? onNumberClick() : null} 
        disabled={used}
      >
        {n}
      </button>
    </div>
  );
};

export default NumberCard;
