
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NumberCard from './components/numberCard';
import NumberSelector from './types/numberSelector';
import { Operation } from './types/operation';
import OperatorButton from './components/OperatorButton';
import { realizeCalculation, selectNumbers, /* solver */ } from './services/fermatServices';
import '../../styles/fermat/Fermat.css';

const Fermat: React.FC = () => {

  const cards = 6;

  const [error, setError] = useState<string>();
  const [targetNumber, setTargetNumber] = useState<number>(0);
  const [selectedNumbers, setSelectedNumbers] = useState<NumberSelector[]>([]);
  const [number1, setNumber1] = useState<number>();
  const [number2, setNumber2] = useState<number>();
  const [operator, setOperator] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [numberFound, setNumberFound] = useState<boolean>(false);
  const [gameStarted, setGameStarted] = useState<boolean>(false);
  const [operations, setOperations] = useState<Operation[]>([]);

  let numbersPossible = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 25, 50, 75, 100];

  const newGame = () => {
    setGameStarted(false);
    setSelectedNumbers(selectNumbers(numbersPossible, cards));
    setTargetNumber(Math.floor(100 + Math.random() * 900));

    setOperations([]);
    setNumberFound(false);
    setNumber1(undefined);
    setNumber2(undefined);
    setOperator(undefined);
  };

  useEffect(() => {
    if (operations.length > 0 || number1 || number2 || operator) {
      setGameStarted(true);
    }
  }, [gameStarted, number1, number2, operator]);

  const updateNumbers = () => {
    if (!number1 || !number2 || !operator) {
      setError('Something is missing...');
      return;
    }

    let newNumber = realizeCalculation(number1, number2, operator);
    operations.push({ num1: number1, num2: number2, operator: operator, result: newNumber });
    setOperations(operations);
    setNumber1(undefined);
    setNumber2(undefined);
    setOperator(undefined);

    selectedNumbers.push({ val: newNumber, initial: false, used: false });
    setSelectedNumbers(selectedNumbers);

    if (newNumber === targetNumber) {
      setNumberFound(true)
    }
  };

  const numberClicked = (nmb: NumberSelector, index: number) => {

    selectedNumbers[index] = { val: nmb.val, initial: nmb.initial, used: true };
    setSelectedNumbers(selectedNumbers);

    if (!number1) {
      setNumber1(nmb.val);
    } else if (!number2) {
      setNumber2(nmb.val);
    } else {
      // setError('Numbers are selected, select an operation.');
    }
  };

  const operatorClicked = (operator: string) => {
    setOperator(operator);
  };

  const removeLastOperation = () => {
    const lastOperation = operations.pop();

    if (!lastOperation) {
      setError("There is no last operation to display");
      return;
    }

    selectedNumbers.pop(); // remove the last number added - the result of the last operation
    // update the numbers who had beed used to "used=false" when deleting the last operation
    const selectedNumbersUpdated = selectedNumbers.map((nmb: NumberSelector) => {
      if (nmb.val === lastOperation.num1 || nmb.val === lastOperation.num2) {
        return { used: false, initial: nmb.initial, val: nmb.val };
      };
      return { used: nmb.used, initial: nmb.initial, val: nmb.val };
    });
    setSelectedNumbers(selectedNumbersUpdated);
  };

  const clearSelection = () => {
    const selectedNumbersUpdated = selectedNumbers.map((nmb: NumberSelector) => {
      if ((nmb.val === number1 || nmb.val === number2) && nmb.used === true) {
        return { val: nmb.val, initial: nmb.initial, used: false };
      }
      return nmb;
    });

    setSelectedNumbers(selectedNumbersUpdated);
    setNumber1(undefined);
    setNumber2(undefined);
    setOperator(undefined);
  };

  // use of a memo to keep the selectedNumbers in memort
  const selectedNumbersMemo = useMemo(() => {
    return selectNumbers([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 25, 50, 75, 100], cards);
  }, []);

  useEffect(() => {
    setSelectedNumbers(selectedNumbersMemo);
    setTargetNumber(Math.floor(100 + Math.random() * 900));
  }, [selectedNumbersMemo]); // le tableau vide indique que ce useEffect ne sera appelé qu'une seule fois à la montée du composant - la fonction peut être appelée sans le tableau vide

  // when an operation is completely written, it executes immediatly
  useEffect(() => {
    if (number1 && number2 && operator) {
      setIsLoading(true);
      setTimeout(() => {
        updateNumbers();
        setIsLoading(false)
      }, 500);
    }
  }, [number1, number2, operator]);

  useEffect(() => {
    // const solvable = solver(selectedNumbers.map((ns: NumberSelector) => ns.val ), targetNumber, false);
  }, [targetNumber]); // cet effet s'exécute à chaque fois que targetNumber est mis à jour

  return (
    <div className='flex items-center flex-col gap-2 lg:gap-4 mx-2 '>

      <div className="w-full">
        <h1 className={"flex font-bold bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent mt-2 justify-center transition-all duration-300 ease-in-out " + (gameStarted ? "text-sm" : "text-3xl")}>
          Fermat
        </h1>
      </div>

      <div className='p-4 w-full lg:w-1/3 rounded-lg bg-gradient-to-r from-blue-100 to-blue-200 shadow-md'>
        <p className='text-[0.8rem] text-blue-600 text-center'>Target</p>
        <p className='text-2xl font-bold text-blue-800 text-center'>{targetNumber}</p>
      </div>

      <div className='flex flex-col w-full lg:w-1/3 gap-4 items-center p-4 rounded-lg bg-neutral-100 shadow-md'>
        <div className='flex inline-flex gap-2'>
          {selectedNumbers.map((nmb: NumberSelector, index: number) => {
            if (!nmb.initial) return;
            return (
              <div key={index}>
                <NumberCard n={nmb.val} used={nmb.used} onNumberClick={() => numberClicked(nmb, index)} />
              </div>
            )
          })}
        </div>

        <div className='flex inline-flex gap-4'>
          <OperatorButton sign="+" onClick={() => operatorClicked('+')} />
          <OperatorButton sign="-" onClick={() => operatorClicked('-')} />
          <OperatorButton sign="x" onClick={() => operatorClicked('x')} />
          <OperatorButton sign="/" onClick={() => operatorClicked('/')} />
        </div>
      </div>

      <div>
        {error &&
          <p>{error}</p>
        }
      </div>

      {!numberFound ?
        <div className='flex flex-col w-full items-center gap-4 '>
          <div className='flex flex-row h-20 gap-4 items-center'>
            <div className='flex flex-row gap-4'>
              {(number1) &&
                <NumberCard n={number1} used={true} onNumberClick={() => { }} />
              }
              {(operator) &&
                <NumberCard n={operator} used={true} onNumberClick={() => { }} />
              }
              {(number2) &&
                <NumberCard n={number2} used={true} onNumberClick={() => { }} />
              }
              {isLoading &&
                <NumberCard n={'='} used={true} onNumberClick={() => { }} />
              }
              {isLoading &&
                <NumberCard n={'?'} used={true} onNumberClick={() => { }} />
              }
            </div>

            {(number1 || operator || number2) && !isLoading &&
              <div className="relative w-auto h-14">
                <button
                  className=
                  'w-full h-full px-2 rounded-lg shadow-lg flex items-center justify-center text-xl font-semibold transform transition duration-200 bg-gradient-to-r from-orange-100 to-orange-50 border border-gray-200 hover:scale-105 hover:shadow-xl hover:border-orange-400 active:scale-95 active:bg-blue-50'
                  onClick={clearSelection}
                >
                  clear
                </button>
              </div>
            }
          </div>
        </div>
        :
        <div className='flex flex-row w-full lg:w-1/3 items-center justify-center gap-4 p-4 bg-gradient-to-r from-green-100 to-green-200 rounded-lg shadow-md'>
          <div className='text-center'>
            <p className='text-xl font-bold text-green-800'>Congratulations! 🎉</p>
            <p className='text-sm text-green-700'>You found the target number!</p>
          </div>
          <div>
            <button
              className='px-6 py-3 bg-gradient-to-r from-green-500 to-emerald-500 text-white font-semibold rounded-lg shadow-lg transform transition duration-200 hover:scale-105 hover:shadow-xl active:scale-95'
              onClick={() => newGame()}
            >
              Play Again
            </button>
          </div>
        </div>
      }


      <div className='flex flex-col gap-4 pt-4 border-t border-gray-200 mb-6'>
        {operations.map((operation: Operation, index: number) => {
          const result = selectedNumbers[cards + index];
          if (!result) return;
          return (
            <div className='flex flex-row gap-4 items-center' key={index}>
              <div className='col-span-4'>
                <div key={index} className='grid grid-cols-5 gap-2'>
                  <NumberCard n={operation.num1} used={true} onNumberClick={() => { }} />
                  <NumberCard n={operation.operator} used={true} onNumberClick={() => { }} />
                  <NumberCard n={operation.num2} used={true} onNumberClick={() => { }} />
                  <NumberCard n={'='} used={true} onNumberClick={() => { }} />
                  <NumberCard n={result.val} used={result.used} onNumberClick={() => numberClicked(result, cards + index)} />
                </div>
              </div>

              <div className="relative w-10 h-14">
                <button
                  className=
                  'w-full h-full rounded-lg shadow-lg flex items-center justify-center text-xl font-semibold transform transition duration-200 bg-gradient-to-r from-orange-100 to-orange-50 border border-gray-200 hover:scale-105 hover:shadow-xl hover:border-orange-400 active:scale-95 active:bg-blue-50'
                  onClick={removeLastOperation}
                >
                  x
                </button>
              </div>
            </div>
          )
        })}
      </div>

      {!numberFound && isLoading &&
        <div className='flex flex-row items-center justify-center gap-4 h-14'>
          <div className="p-2 rounded-lg bg-gradient-to-r from-blue-500 to-sky-400 shadow-md animate-pulse">
            <p className="text-white font-semibold text-center">Doing some calculations...</p>
          </div>
        </div>
      }


    </div>
  );
};

export default Fermat;
